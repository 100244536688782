import React, { useState } from 'react';
import ContentWrapper from '../components/ContentWrapper';
import SoppelSorteringImage from'../images/portfolio/HogskolenVolda/HeroImage.jpg';
import NRKVideo from'../images/portfolio/NRK/nrk_video.png';
import RotsetHornetImage from '../images/portfolio/Rotsethornet/HeroImage.jpg';
import Skolekinodagen from '../images/portfolio/FilmOgKino/video-thumbnail.jpg';
import FiletSkolenImage from '../images/portfolio/FiletSkolen/HeroImage.jpg';
import CreazaImage from '../images/portfolio/Creaza/HeroImage.jpg';
import AlphabetPlanetImage from '../images/portfolio/Creaza/AlfabetPlaneten/thumbnail.jpg';
import IPR from '../images/portfolio/IPR/tannpuss-2.png';
import Title from '../images/titles/portefoelje.svg';
import Refinansiering from '../images/portfolio/Sparebank1/refinansiering.png';
import RoomOfMirrorsThumbnail from '../images/portfolio/Tristious/room-of-mirrors.png';

//Posters
import DodensPraktikantPoster from '../images/portfolio/DodensPraktikant/poster.png'
import PulverHeksaPoster from '../images/portfolio/PulverHeksa/poster.png'
import VDPoster from '../images/portfolio/ViciousDogs/poster.png'
import SantaIsDone from '../images/portfolio/CrispinGlover/poster.png'
import AlienPlanetsPoster from '../images/portfolio/AlienPlanets/poster.png'

import { Link } from 'gatsby';
import '../styles/portfolio.css';


const Poster = ({projectName, imgSrc}) => {
  return <Link to={`/portfolio/${projectName}`}>
     <div className="max-w-sm sm:max-w-md md:max-w-3xs rounded">
      <img className="max-w-sm sm:max-w-md md:max-w-3xs rounded" src={imgSrc} />
    </div>
  </Link>
}

const About = () => {
    const [showVideo, setShowVideo] = useState(false); 
    const [showAllClientProjects, setShowAllClientProjects] = useState(true);
    
    const onVideoSelect = (videoUrl) => {
      setShowVideo(videoUrl)
    }

    const clientProjects = [
      {
        image: RoomOfMirrorsThumbnail,
        client: "Tristious",
        title: "Metallica",
        projectname: "tristious"
      },
      {
        image: Refinansiering,
        client: "SpareBank 1",
        title: "Refinansiering og forbukslån" ,
        projectname: "sparebank-1"
      },
      {
        image: IPR,
        client: "IPR (WeMake)",
        title: "Sinte barn og sinte voksne" ,
        projectname: "IPR"
      },
      {
        image: NRKVideo,
        client: "",
        title: "NRK" ,
        description: <div>
          <p>“Andreas Wahl har alltid drømt om å få være vektløs som en astronaut. <br /> Nå har han fått sjansen til å forklare hvorfor vektløshet <br /> er det samme som å være i fritt fall.”</p>
        </div>,
        projectname: "nrk"
      },
      {
        image: AlphabetPlanetImage,
        client: "Creaza",
        title: "Alfabetplaneten" ,
        projectname: "alfabetplaneten"
      },
      {
        image: Skolekinodagen,
        client: "Film og Kino",
        title: "Den Store Skolekinodagen" ,
        projectname: "film-og-kino"
      },
      {
        image: CreazaImage,
        client: "Creaza",
        title: "Kreative filmer for nettside",
        description: "Vil du lære på en underholdene og kreativ måte?",
        projectname: "creaza"
      },
      {
        image: RotsetHornetImage,
        client: "Oclin",
        title: "Rotsethornet",
        description: "Få med deg hva Rothsethornet har å si!",
        projectname: "rotsethornet"
      },
      {
        image: FiletSkolenImage,
        client: "Cod Cluster",
        title: "Filetskolen modul 2" ,
        projectname: "filetskolen"
      },
      {
        image: SoppelSorteringImage,
        client: "Høgskulen volda",
        title: "Søppelsortering" ,
        projectname: "hogskolenvolda"
      },
    ];

   

    return  <ContentWrapper>
          <div className="w-full flex justify-center">
            <img src={Title} className="w-72 text-center  mx-auto mt-16 mb-12" />
          </div>
        
         <div className="mb-8 mx-auto">
        <div className="flex flex-wrap  gap-6 justify-center">
            <div className="flex flex-wrap gap-6 justify-center">
              <Poster imgSrc={DodensPraktikantPoster} projectName={"dodens-praktikant"}/>
              <Poster imgSrc={SantaIsDone} projectName={"crispin-glover"}/>
            </div>
            <div className="flex flex-wrap gap-6 justify-center">
              <Poster imgSrc={VDPoster} projectName={"vicious-dogs"}/>
              <Poster imgSrc={AlienPlanetsPoster} projectName={"alien-planets"}/>
            </div>
            <div>
              <Poster imgSrc={PulverHeksaPoster} projectName={"pulverheksa"}/>
            </div>
            
            
 
          </div>
        </div>      
        <div className="mb-8 mt-20">
        <p className="text-center text-3xl mb-4">Tidligere oppdrag</p>
        <div className="flex flex-wrap justify-center">
          
          {clientProjects.map((project, index) => {
            if(showAllClientProjects) {
              return <PortfolioProject
                image={project.image}
                client={project.client}
                title={project.title}
                description={project.description}
                projectname={project.projectname}
              />
            } else {
              if(index < 2) {
                return <PortfolioProject
                  image={project.image}
                  client={project.client}
                  title={project.title}
                  description={project.description}
                  projectname={project.projectname}
                />
              }
            }
          })}
        </div>
       
        </div>
        
      
      </ContentWrapper>

}

const PortfolioProject = ({header, image, client, title, description, projectname}) => {
  return <Link to={`/portfolio/${projectname}`}><div className="p-4 mb-4 max-w-md" >
    <div className="overflow-hidden max-w-xs md:max-w-md max-h-64 md:h-64">
      <img className="max-w-xs md:max-w-md" src={image} />
    </div>

    <p className="mt-7">{client}</p>
    <p className="text-xl mt-1 mb-2">{title}</p>
    {description && <p className="mb-8 max-w-xs md:max-w-md">{description}</p>}
     <p className="underline text-tb-orange  cursor-pointer">Les mer</p>
  </div></Link>
}



export default About;


/*

  <div className="mb-16 flex flex-wrap  justify-center ">
        <Video onClick={onVideoSelect} url="https://player.vimeo.com/video/599574162" title="Vannprøve demonstrasjon - BALAB" thumbnail={WaterDemoImage} />
          <Video onClick={onVideoSelect} url="https://player.vimeo.com/video/599572882" title="Søppelsortering - Høgskulen i Volda" thumbnail={SoppelSorteringImage} />
          <Video onClick={onVideoSelect} url="https://player.vimeo.com/video/599572216" title="Rotsethornet" thumbnail={RotsetHornetImage} />
          <Video onClick={onVideoSelect} url="https://player.vimeo.com/video/599570858" title="Filetskolen" thumbnail={FiletSkolenImage} />
          <Video onClick={onVideoSelect} url="https://player.vimeo.com/video/599568072" title="Bygge et hus - Creaza" thumbnail={CreazaByggeHusImage} />
          <Video onClick={onVideoSelect} url="https://player.vimeo.com/video/599573705" title="Naturen - Creaza" thumbnail={CreazaNaturenImage} />
          <Video onClick={onVideoSelect} url="https://player.vimeo.com/video/651689150" title="No Santa Clauses - Crispin Glover Records" thumbnail={NoSantaClausesImage} />
          <VideoOverlay isVisible={showVideo} setVisible={setShowVideo} /> }

          </div>
          <p className="font-bold mb-4">For våre kunder</p>
          <div className='flex flex-wrap '>
            <PortfolioLink name="crispin-glover" displayName="Crispin Glover" thumbnail={NoSantaClausesImage} />
            <PortfolioLink name="creaza" displayName="Creaza" thumbnail={CreazaImage} />
            <PortfolioLink name="rotsethornet" displayName="Rotsethornet" thumbnail={RotsetHornetImage} />
            <PortfolioLink name="filetskolen" displayName="Filetskolen" thumbnail={FiletSkolenImage} />
            <PortfolioLink name="hogskolenvolda" displayName="Høgskulen Volda" thumbnail={SoppelSorteringImage} />
          </div>
  
          <p className="font-bold mb-4 mt-8">Våre kortfilmer</p>
          <div className='flex flex-wrap '>
            <PortfolioLink name="alien-planets" displayName={"Alien Planets Ep. 1"} thumbnail={AlienPlanetsImage} />
            <PortfolioLink name="alien-planets-2" displayName={"Alien Planets Ep. 2"} thumbnail={AlienPlanetsImage2} />
            <PortfolioLink name="vicious-dogs" displayName="Vicious Dogs" thumbnail={ViciousDogsImage} />
          </div>

          */